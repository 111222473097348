<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" v-if="is.initialised">

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Usage" v-model="model.status" :validation="$v.model.status" :options="$constants.schedule.room.publicName" :descriptions="$constants.schedule.room.publicDescription" />
			<app-input-checklist label="Event Types" notes="Select which event types can be submitted to this room." v-model="model.types" :validation="$v.model.types" :options="typesOptions" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" title="Access" subtitle="Badge holders that can see this room when submitting events" icon="moderators" v-if="model.status === 2">

			<app-input-suggest :multiple="true" placeholder="Search badge" v-model="model.access" api="convention/badge" :validation="$v.model.access" notes="You do not need to add admins or moderators as they already will have access." />

		</app-content-box>

		<app-content-section v-if="!isNew" route="Convention.Schedule.Spaces" :params="{ room: model.id }" title="Spaces" description="Manage the spaces that can be booked within this room. If no spaces are available then the attendee will be allowed to enter directions. " :count="item.count.spaces" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			references: {
				types: []
			},
			model: {
				id: false,
				name: '',
				status: 1,
				types: []
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			status: {
				required
			},
			types: {
				required
			}
		}
	},

	computed: {

		typesOptions: function() {

			var options = {}

			this.$_.each(this.references.types, function(type) {

				options[type.id] = type.name

			})

			return options

		},

	}

}

</script>

<style scoped>

</style>